import React, { useState, useEffect, useContext, useRef } from 'react';
import { ParkingDataContext } from '../contexts/ParkingDataContext';

const AutoRefreshToggle = () => {
    const { fetchParkingData } = useContext(ParkingDataContext);
    const [autoRefreshEnabled, setAutoRefreshEnabled] = useState(true);
    const [countdown, setCountdown] = useState(60);
    const intervalRef = useRef(null);

    useEffect(() => {
        if (autoRefreshEnabled) {
            intervalRef.current = setInterval(() => {
                setCountdown(prevCountdown => {
                    if (prevCountdown === 1) {
                        fetchParkingData();
                        return 60;
                    }
                    return prevCountdown - 1;
                });
            }, 1000);
        }

        return () => clearInterval(intervalRef.current);
    }, [autoRefreshEnabled, fetchParkingData]);

    const handleToggleChange = () => {
        setAutoRefreshEnabled(!autoRefreshEnabled);
        setCountdown(60); // Reset countdown when enabling auto-refresh
    };

    return (
        <div className="flex shrink-0 items-center">
            <label className="inline-flex items-center cursor-pointer">
                <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={autoRefreshEnabled}
                    onChange={handleToggleChange}
                />
                <div className="relative w-11 h-6 bg-slate-400 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-500"></div>
                <span className="ml-3 font-medium text-white" style={{ width: "140px" }}>
                    Auto refresh <span id="countdown-timer" className="text-slate-200">({countdown}s)</span>
                </span>
            </label>
        </div>
    );
};

export default AutoRefreshToggle;