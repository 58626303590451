import React from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import AutoRefreshToggle from './components/AutoRefreshToggle';
import { ParkingDataProvider } from './contexts/ParkingDataContext';
import './App.css';

const App = () => {
  return (
    <ParkingDataProvider>
      <ScrollRestoration />
      <div className="container mx-auto my-5 p-4 ">
        <h1 className="text-3xl text-white font-bold mb-4">Real time bezetting van de parkings in Gent</h1>
        <div className="flex flex-wrap justify-between items-center mb-10">
          <p className="text-white mb-2">Live gegevens over de parkeergarages in Gent en hun bezetting in real time. Bekijk waar je kan parkeren in Gent.</p>
          <AutoRefreshToggle />
        </div>
      <Outlet />
      <p className="my-20 text-white text-sm">
          Realtime bezettingsgraad van de parkeergarages in Gent. Live beschikbaarheid parkings in Gent.<br />
          Bron: <a href="https://data.stad.gent/" target="_blank" rel="noreferrer noopener nofollow" className="text-slate-100 hover:text-white hover:underline">Gent Open Data Portaal</a>.
        </p>
      </div>
    </ParkingDataProvider>
  );
};

export default App;