import React, { useContext, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ParkingDataContext } from '../contexts/ParkingDataContext';
import { LoadingIcon, BackIcon, WarningIcon, ClockIcon, EuroIcon, ParkingIcon, LezIcon, ExternalIcon, DirectionsIcon } from './Icons';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from "leaflet";

const ParkingDetail = () => {
    const { id } = useParams();
    const { parkingData, prParkingData, loading, dataLoaded } = useContext(ParkingDataContext);

    const allRecords = [...parkingData, ...prParkingData];
    const parking = allRecords.find(p => p.name.toLowerCase().replace(/ /g, '-') === id);

    useEffect(() => {
        if (parking) {
            document.title = `Parking ${parking.name} - Real time bezetting - Parkings.gent`;
        } else {
            document.title = 'Parkings.gent - Real time bezetting parkeergarages Gent';
        }

        return () => {
            document.title = 'Parkings.gent - Real time bezetting parkeergarages Gent';
        };
    }, [parking]);

    if (loading && !dataLoaded) {
        return (<div role="status"><LoadingIcon className="inline w-6 h-6 text-white animate-spin fill-blue-500 mr-2" /><span className="text-white font-bold">Loading...</span></div>);
    }

    if (!dataLoaded) {
        return <p className="text-white font-bold">Momenteel zijn er geen parkeergegevens beschikbaar. Probeer het later opnieuw.</p>;
    }

    if (!parking) {
        return <p className="text-white font-bold">Parking niet gevonden.</p>;
    }

    const position = [parking.locationLat, parking.locationLon]
    const marker_svg = `data:image/svg+xml;utf8,${encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="${parking.ratio < 0.1 ? '#EF4444' : parking.ratio < 0.5 ? '#FB923C' : '#22C55E'}" stroke="#FFF" stroke-width="10" d="M192 512s192-208 192-320C384 86 298 0 192 0S0 86 0 192C0 304 192 512 192 512zm0-384a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>`)}`;

    const marker = L.icon({
        iconUrl: marker_svg,
        iconSize: [40, 40],
        iconAnchor: [20, 20],
        popupAnchor: [0, -20],
    });

    const description = parking.description ? parking.description.replace(/^\?\s*/, '') : '';

    return (
        <div>
            <Link to="/" className="inline-flex items-center bg-white text-blue-800 rounded-full border border-blue-400 hover:text-white hover:bg-blue-900 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-700 py-1 px-3 mb-5">
                <BackIcon className="w-4 h-4 mr-2" />
                Alle parkings
            </Link>
            <div className="bg-white p-6 rounded-lg shadow-md text-center mb-5">
                <h3 className="text-2xl font-semibold my-2">{parking.name}</h3>
                <p className="mb-5">{description}</p>

                {parking.brokencountingsys === 'True' && (<div className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10"><WarningIcon className="w-4 h-4 mr-2" />Telsysteem kapot</div>)}
                <p className={`text-4xl font-bold ${parking.ratio < 0.1 ? 'text-red-500' : parking.ratio < 0.5 ? 'text-orange-400' : 'text-green-500'}`} title="Beschikbare parkeerplaatsen">
                    {parking.availableCapacity}<span className="text-sm text-gray-500 mb-2"> / {parking.totalCapacity}</span>
                </p>
                <p className="mb-5 text-sm text-gray-500">Beschikbare parkeerplaatsen</p>
                
                <hr className="mb-5" />

                <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 gap-y-10 mb-8">
                    <div className="flex flex-col items-center">
                        <ClockIcon className="w-16 h-16 text-slate-500" />
                        <p className="text-xs text-gray-500 uppercase mt-3">Openingsuren</p>
                        {parking.temporaryclosed === 1 && (<div className="inline-flex items-center rounded-md bg-red-50 my-2 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10"><WarningIcon className="w-4 h-4 inline mr-2" />Tijdelijk gesloten</div>)}
                        <p>{parking.isOpen}</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <EuroIcon className="w-16 h-16 text-slate-500" />
                        <p className="text-xs text-gray-500 uppercase mt-3">Parkeertarief</p>
                        <p>{parking.freeparking} parkeren</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <ParkingIcon className="w-16 h-16 text-slate-500" />
                        <p className="text-xs text-gray-500 uppercase mt-3">Operator</p>
                        <p>{parking.operatorinformation}</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <LezIcon className="w-16 h-16 text-slate-500" />
                        <p className="text-xs text-gray-500 uppercase mt-3">Lage-emissiezone</p>
                        <p className="first-letter:uppercase">{parking.lez}</p>
                    </div>
                </div>
                <hr className="mb-5" />

                <p className="mb-4">Meer informatie over parking {parking.name} {parking.freeparking === 'Betalend' && ('inclusief parkeertarieven')} is te vinden op de officiële website van de operator.</p>

                <a href={parking.url} target="_blank" rel="noopener noreferrer" className={`inline-flex items-center py-1 px-3 ${parking.url !== '#' ? 'bg-white text-blue-800 rounded-full border border-blue-400 hover:text-white hover:bg-blue-900 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-700' : 'text-gray-500 cursor-not-allowed'}`}>Website parking<ExternalIcon className="w-4 h-4 ml-2" /></a>

                <MapContainer className="h-96 rounded-lg mt-10 mb-5" center={position} zoom={17} scrollWheelZoom={false}>
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <Marker position={position} icon={marker}><Popup>Parking {parking.name}</Popup></Marker>
                </MapContainer>

                <a href={parking.locationLink} target="_blank" rel="noopener noreferrer" className={`inline-flex items-center mb-10 py-1 px-3 ${parking.locationLink !== '#' ? 'bg-white text-blue-800 rounded-full border border-blue-400 hover:text-white hover:bg-blue-900 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-700' : 'text-gray-500 cursor-not-allowed'}`}><DirectionsIcon className="w-4 h-4 mr-2" />Routebeschrijving</a>
            </div>
            <Link to="/" className="inline-flex items-center bg-white text-blue-800 rounded-full border border-blue-400 hover:text-white hover:bg-blue-900 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-700 py-1 px-3 mb-4">
                <BackIcon className="w-4 h-4 mr-2" />
                Alle parkings
            </Link>
        </div>
    );
};

export default ParkingDetail;