// src/routes.js
import { createBrowserRouter } from 'react-router-dom';
import App from './App';
import ParkingList from './components/ParkingList';
import ParkingDetail from './components/ParkingDetail';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <ParkingList />,
      },
      {
        path: 'parking/:id',
        element: <ParkingDetail />,
      },
    ],
  },
]);

export default router;