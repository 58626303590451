import React, { createContext, useState, useEffect, useRef, useMemo } from 'react';
import debounce from 'lodash.debounce';

export const ParkingDataContext = createContext();

const MAX_RETRIES = 5;

const formatParkingRecord = (parking, index, isInterparking = false) => ({
    id: `${parking.name}-${index}`,
    name: parking.name,
    type: isInterparking ? '' : parking.type,
    availableCapacity: Math.max(parking.availablecapacity ?? parking.availablespaces, 0),
    totalCapacity: parking.totalcapacity ?? parking.numberofspaces,
    isOpen: isInterparking ? 'Open 24/7' : (parking.isopennow ? `Open ${parking.openingtimesdescription}` : 'Gesloten'),
    isOpenClass: parking.isopennow ? 'inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20' : 'bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded',
    temporaryclosed: parking.temporaryclosed || '',
    freeparking: parking.freeparking ? 'Gratis' : 'Betalend',
    locationLat: parking.location?.lat ?? parking.geo_point_2d?.lat,
    locationLon: parking.location?.lon ?? parking.geo_point_2d?.lon,
    locationLink: `https://www.google.com/maps/dir/current+location/${parking.location?.lat ?? parking.geo_point_2d?.lat},${parking.location?.lon ?? parking.geo_point_2d?.lon}/`,
    ratio: (parking.availablecapacity ?? parking.availablespaces) / (parking.totalcapacity ?? parking.numberofspaces),
    description: parking.description || '',
    operatorinformation: isInterparking ? 'Interparking' : parking.operatorinformation,
    lez: isInterparking ? 'Parking in LEZ' : (parking.categorie || 'Parking buiten LEZ'),
    text: parking.text || '',
    url: parking.urllinkaddress || parking.url,
    brokencountingsys: parking.brokencountingsys || ''
});

export const ParkingDataProvider = ({ children }) => {
    const [parkingData, setParkingData] = useState([]);
    const [prParkingData, setPrParkingData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dataLoaded, setDataLoaded] = useState(false);

    const isFetching = useRef(false);
    const retryCount = useRef(0);

    const fetchParkingData = useRef(debounce(async () => {
        if (isFetching.current) return;
        isFetching.current = true;
        setLoading(true);

        const parkingApiUrl = process.env.REACT_APP_PARKING_API_URL;
        const interparkingApiUrl = process.env.REACT_APP_INTERPARKING_API_URL;
        const prParkingApiUrl = process.env.REACT_APP_PR_PARKING_API_URL;

        try {
            const [parkingResponse, interparkingResponse, prParkingResponse] = await Promise.all([
                fetch(parkingApiUrl),
                fetch(interparkingApiUrl),
                fetch(prParkingApiUrl)
            ]);

            if (!parkingResponse.ok || !interparkingResponse.ok || !prParkingResponse.ok) {
                throw new Error('One or more fetch requests failed.');
            }

            const parkingData = await parkingResponse.json();
            const interparkingData = await interparkingResponse.json();
            const prParkingData = await prParkingResponse.json();

            const parkingRecords = parkingData.results?.map((parking, index) => formatParkingRecord(parking, index)) || [];
            const interparkingRecords = interparkingData.results?.map((interparking, index) => formatParkingRecord(interparking, index, true)) || [];
            const allRecords = [...parkingRecords, ...interparkingRecords].sort((a, b) => a.availableCapacity - b.availableCapacity);
            setParkingData(allRecords);

            const prParkingRecords = prParkingData.results?.map((parking, index) => formatParkingRecord(parking, index)) || [];
            setPrParkingData(prParkingRecords);

            setDataLoaded(true);
            retryCount.current = 0;
        } catch (error) {
            console.error("Failed to fetch parking data:", error);
            if (retryCount.current < MAX_RETRIES) {
                retryCount.current += 1;
                console.log(`Retrying fetch... Attempt ${retryCount.current}`);
                isFetching.current = false;
                fetchParkingData.current();
            } else {
                console.error("Failed to fetch parking data after multiple attempts.");
            }
        } finally {
            setLoading(false);
            isFetching.current = false;
        }
    }, 300));

    useEffect(() => {
        fetchParkingData.current();
    }, []);

    const contextValue = useMemo(() => ({
        parkingData,
        prParkingData,
        loading,
        dataLoaded,
        fetchParkingData: fetchParkingData.current
    }), [parkingData, prParkingData, loading, dataLoaded]);

    return (
        <ParkingDataContext.Provider value={contextValue}>
            {children}
        </ParkingDataContext.Provider>
    );
};