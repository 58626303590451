import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ParkingDataContext } from '../contexts/ParkingDataContext';
import { InfoIcon, LoadingIcon, WarningIcon } from './Icons';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from "leaflet";

const ParkingList = () => {
    const { parkingData, prParkingData, loading, dataLoaded } = useContext(ParkingDataContext);
    const combinedParkingData = [...parkingData, ...prParkingData];

    if (loading && !dataLoaded) {
        return (<div role="status"><LoadingIcon className="inline w-6 h-6 text-white animate-spin fill-blue-500 mr-2" /><span className="text-white font-bold">Loading...</span></div>);
    }

    if (!dataLoaded) {
        return <p className="text-white font-bold">Momenteel zijn er geen live parkeergegevens beschikbaar. Probeer het later opnieuw.</p>;
    }

    return (
        <div>
            <div className="mb-8">
                <MapContainer className="h-96 rounded-lg shadow-md" center={[51.054684, 3.7212231]} zoom={12} scrollWheelZoom={false}>
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                    {combinedParkingData.map(parking => {
                        const marker = L.icon({
                            iconUrl: `data:image/svg+xml;utf8,${encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="${parking.ratio < 0.1 ? '#EF4444' : parking.ratio < 0.5 ? '#FB923C' : '#22C55E'}" stroke="#FFF" stroke-width="10" d="M192 512s192-208 192-320C384 86 298 0 192 0S0 86 0 192C0 304 192 512 192 512zm0-384a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>`)}`,
                            iconSize: [40, 40],
                            iconAnchor: [20, 20],
                            popupAnchor: [0, -20],
                        });

                        return (
                            <Marker key={parking.id} position={[parking.locationLat, parking.locationLon]} icon={marker}>
                                <Popup>
                                    <h4 className="text-lg font-semibold">{parking.name}</h4>
                                    {parking.brokencountingsys === 'True' && (<div className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10"><WarningIcon className="w-4 h-4 mr-2" />Telsysteem kapot</div>)}
                                    <div className={`text-xl font-bold ${parking.ratio < 0.1 ? 'text-red-500' : parking.ratio < 0.5 ? 'text-orange-400' : 'text-green-500'}`}>{parking.availableCapacity}<span className="text-sm text-gray-500 mb-2"> / {parking.totalCapacity}</span>
                                    </div>
                                    <div className="mb-2 text-sm text-gray-500">Beschikbare plaatsen</div>
                                    <Link className="px-2 bg-white text-blue-800 rounded-full border border-blue-400 hover:text-white hover:bg-blue-700 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-700" key={parking.id} to={`/parking/${parking.name.toLowerCase().replace(/ /g, '-')}`}>
                                        Meer info
                                    </Link>
                                </Popup>
                            </Marker>
                        );
                    })}
                </MapContainer>
            </div>

            <h2 className="text-2xl text-white font-bold mb-4">Parkeergarages</h2>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-8">
                {parkingData.map(parking => (
                    <Link key={parking.id} to={`/parking/${parking.name.toLowerCase().replace(/ /g, '-')}`}>
                        <div className="group transition-all duration-200 relative bg-white p-6 h-full rounded-lg shadow-md hover:shadow-lg border border-transparent hover:border-blue-500 text-center cursor-pointer">
                            <InfoIcon className="fill-blue-200 group-hover:fill-blue-400 w-4 h-4 absolute top-3 right-3" />
                            <h3 className="text-xl font-semibold mb-2">{parking.name}</h3>
                            <p className={`text-3xl font-bold ${parking.ratio < 0.1 ? 'text-red-500' : parking.ratio < 0.5 ? 'text-orange-400' : 'text-green-500'}`} title="Beschikbare parkeerplaatsen">
                                {parking.availableCapacity}<span className="text-sm text-gray-500 mb-2"> / {parking.totalCapacity}</span>
                            </p>
                        </div>
                    </Link>
                ))}
            </div>
            <h2 className="text-2xl text-white font-bold mb-4">Park & Ride (P+R) parkeergarages</h2>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {prParkingData.map(parking => (
                    <Link key={parking.id} to={`/parking/${parking.name.toLowerCase().replace(/ /g, '-')}`}>
                        <div className="group transition-all duration-200 relative bg-white p-6 h-full rounded-lg shadow-md hover:shadow-lg border border-transparent hover:border-blue-500 text-center cursor-pointer">
                            <InfoIcon className="fill-blue-200 group-hover:fill-blue-400 w-4 h-4 absolute top-3 right-3" />
                            <h3 className="text-xl font-semibold mb-2">{parking.name}</h3>
                            {parking.brokencountingsys === 'True' && (<div className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10"><WarningIcon className="w-4 h-4 mr-2" />Telsysteem kapot</div>)}
                            <p className={`text-3xl font-bold ${parking.ratio < 0.1 ? 'text-red-500' : parking.ratio < 0.5 ? 'text-orange-400' : 'text-green-500'}`} title="Beschikbare parkeerplaatsen">
                                {parking.availableCapacity} <span className="text-sm text-gray-500 mb-2">/ {parking.totalCapacity}</span>
                            </p>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default ParkingList;